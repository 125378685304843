import { createI18n } from "vue-i18n";

const messages = {
  en: {
    customerComponent: {
      releasedForShipping: "Released for shipping",
      amount: "Amount",
    },
    tipTapEditor: {
      inputImagePlaceholder: "Enter url",
      footer: "Footer",
    },
    tinyEditor: {
      failedDownloadFooterContent: "Failed when download footer content",
      footer: "Footer",
    },
    modalsComponent: {
      changePaymentInfo: {
        title: "Update payment",
        paymentId: "Payment id",
        paymentDate: "Payment date",
        paymentType: "Payment type",
        failedDownloadDictionary: "Failed get payment type dictionary data",
        successUpdatedPayment: "Successfully updated payment",
        partialSuccessUpdatedPayment:
          "Successfully updated payment, but info comment not added",
      },
      changeShippingData: {
        title: "Update shipping info",
        price: "Price",
        shippingType: "Shipping type",
        shippingItem: "Index",
        failedDownloadDictionary: "Failed when get dictionary data",
        successUpdatedShipping: "Successfully updated shipping data",
        partialSuccessUpdatedShipping:
          "Successfully updated shipping data, but info comment not added",
      },
    },
    uploaderFileComponent: {
      firstTextPart1: "Upload a file",
      firstTextPart2: "or drag and drop",
      secondText: "PNG, JPG, GIF up to 10MB",
      noName: "No name",
    },
    ticket: {
      addTicketModal: {
        addTicketTitle: "Add ticket",
        firstTabTitle: "Details",
        secondTabTitle: "Content & Comment",
        thirdTabTitle: "Attachments",
        customerId: "Customer id",
        queue: "Queue",
        category: "Category",
        status: "Status",
        subject: "Subject",
        directionSendEmail: "Email sending direction",
        behalfOfClient: "On behalf of client",
        toClient: "To client",
        source: "Source",
        comment: "Comment",
        tags: "Tags",
        body: "Body",
        email: "Email",
        phone: "Phone",
        sms: "SMS",
        chat: "Chat",
        website: "Website",
        emailTo: "Email to",
        emailFrom: "Email from",
        failedDownloadDictionary: "Failed get dictionary data",
      },
      addTicketMessageModal: {
        addTicketMessageTitle: "Add message",
        typeMessage: "Type message",
        message: "Message",
        comment: "Comment",
        bodyText: "Text",
      },
      ticketList: {
        searchTickets: "Search tickets",
        selectUser: "Select user",
        selectStatus: "Select state",
        selectQueue: "Select queue",
        addTicket: "Add ticket",
        failedDownloadTickets: "Failed get tickets",
        failedDownloadTicketsConfirmButtonText: "Ok, got it!",
        confirmRemoveTicket:
          "Are you sure you want to delete ticket {number} ?",
        successfullyRemovedTicket: "Ticket is successfully removed",
        columnTicketId: "Ticket Id",
        columnTitle: "Title",
        columnCount: "Count",
        columnFullStatusName: "Status",
        columnCreatedDate: "Created date",
        columnAssignedUser: "Assigned user",
      },
      ticketDetail: {
        addMessage: "Add message",
        tabMessages: "Messages",
        queue: "Queue",
        emailFrom: "Email from",
        assignedLogin: "Assigned login",
        lastUpdated: "Last updated",
        category: "Category",
        status: "Status",
        successfullyDownloadTicket: "Ticket download data successfully",
        failedDownloadTicket: "Ticket download data failed",
        successfullyUpdatedTicket: "Ticket updated successfully",
        failedUpdateTicket: "Ticket updated is failed",
        attachmentFileName: "Attachment",
        failedDownloadAttachment: "Attachment download failed",
      },
    },
    serviceReq: {
      detailsTitle: "Service request details",
      searchText: "Search service requests",
      failedDownloadServiceReqs: "Error when download service requests",
      failedDownloadServiceReq: "Error when download service request details",
      docDate: "Document date",
      createDate: "Created date",
      createdTime: "Created time",
      documentNumber: "Document number",
      marketPlace: "MarketPlace",
      referenceNumber: "Reference number",
      customerCode: "Customer code",
      clientName: "Client name",
      taxNumber: "NIP/TAX",
      datePurchase: "Date of purchase",
      relatedDocuments: "Related documents",
      header: "Header",
      lines: "Lines in document",
      order: "Order",
      delivery: "Delivery",
      invoice: "Invoice",
      serviceOrders: "Service orders",
      serviceRequestNumber: "Service request number",
      trackNo: "Track number",
      courier: "Courier",
      status: "Status",
      linesTable: {
        index: "Index",
        itemName: "Item name",
        quantity: "Quantity",
        description: "Description",
        causeComplaint: "Cause for complaint",
        decision: "Decision",
        status: "Status",
        typeReport: "Type of report",
        solution: "Solution",
        customerRequest: "Customer request",
      },
      comment: {
        failedDownloadComments: "Failed download comments",
        commentsTitle: "Comments",
        addCommentTitle: "Add comment",
        addCommentBtn: "Add comment",
        userEmail: "Email",
        createdDate: "Created date",
        commentContent: "Comment content",
        confirmRemove: "Are you sure you want to delete comments ?",
        successfullyRemoved: "Comment is successfully removed",
      },
    },
    global: {
      light: "Light",
      dark: "Dark",
      lang: {
        english: "English",
        german: "German",
        polish: "Polish",
      },
      clickHere: "Click here",
      search: "Search",
      select: "Select",
      add: "Add",
      send: "Send",
      update: "Update",
      cancel: "Cancel",
      canceled: "Canceled",
      back: "Back",
      pleaseWait: "Please wait",
      next: "Next",
      yes: "Yes",
      no: "No",
      download: "Download",
      noDataFound: "No data found",
      from: "From",
      view: "View",
      remove: "Remove",
      loading: "Loading",
      confirmBrakeLoopGetData:
        "Do you want to finish downloading data and go to another page ?",
      actions: "Actions",
      startDate: "Start date",
      endDate: "End date",
      invalidDate: "Invalid date!",
      saveChanges: "Save changes",
      successfullySendFromPopup: "Form has been successfully submitted",
      failedTextPopup:
        "Sorry, looks like there are some errors detected, please try again",
      confirmButtonTextPopup: "Ok, got it!",
      formValid: {
        validEmail: "Must be a valid email",
        validRequiredWithName: "{name} is required",
        validExistInList: "This value exist in list",
        validRequired: "This field is required",
        validMatchWithName: "{name} must be the same",
        validMinWithNumber: "The minimum number of characters is {number}",
        validMaxNowDateWithName: "{name} cannot be more than today",
        validMinDateWithTwoName: "{name} must be after then {name2}",
        validOneOfElementsRequired: "One of element is required",
        validOnlyNumber: "Must be only digits",
        validPhoneNumber: "Must be correct phone number",
        validRequiredWithNameWhenCheckedName:
          "{name} is required when {name2} is selected",
        validRequiredOrInvalidDate: "Required field or invalid date",
        validPasswordCharacters:
          "Password must Contain 8 characters, one uppercase, one lowercase, one number and one special case character",
      },
    },
    documentComponent: {
      comment: {
        failedDownloadComments: "Failed download comment",
        commentsTitle: "Comments",
        addCommentTitle: "Add comment",
        addCommentBtn: "Add comment",
        userEmail: "Email",
        createdDate: "Created date",
        commentContent: "Comment content",
        confirmRemove: "Are you sure you want to delete comments ?",
        successfullyRemoved: "Comment is successfully removed",
      },
      headInfo: {
        confirmCancelDoc: "Are you sure you want to cancel this document ?",
        successfullyCanceledDoc: "Document is successfully canceled",
        partialSuccessfullyCanceledDoc:
          "Document is successfully canceled, but info comment not added",
        cancelDocBtn: "Cancel document",
        orderIsCanceled: "Order is canceled !!!",
      },
    },
    dashboardComponent: {
      OrdersLast1Days: "Yesterday",
      OrdersLast0Days: "Today",
      Highlights30Days: "Summary last 30 days",
      Highlights7Days: "Summary last 7 days",
      OpenRelesedProcentLast30Days: "Released for shipment last 30 days",
      PackedAndShipedPercentLast30Days: "Packed and shipped last 30 days",
      OpenRelesedProcentLast7Days: "Released for shipment last 7 days",
      PackedAndShipedPercentLast7Days: "Packed and shipped last 7 days",
      failedDownloadStatisticalData: "Failed get statistical data from SAPI",
    },
    items: {
      itemQuantityListForm: {
        lineName: "Name",
        lineIndex: "Index",
        lineQuantity: "Quantity",
        failedDownloadItems: "Failed when download items",
      },
    },
    goodsRelease: {
      addDraftDocModal: {
        comment: "Comment",
        title: "Add new draft goods release document",
        itemsList: "Items list",
        itemsListInfo: "One element is required",
      },
      createdDate: "Created date",
      searchDoc: "Search documents",
      createdDocument: "Created document",
      failedDownloadReleaseDoc: "Failed when download documents",
      draftDocEntry: "Draft DocEntry",
      docEntry: "DocEntry",
      comment: "Comment",
      draftDocNum: "Draft document number",
      docNum: "Document number",
      draftLine: "Draft goods release lines",
      releasedLine: "Goods release lines",
      failedDownloadReleasedDetails: "Failed when download released details",
      failedDownloadItems: "Failed when download items",
      addDraftDoc: "Add draft document",
      lineName: "Name",
      lineIndex: "Index",
      lineQuantity: "Quantity",
    },
    returnsView: {
      lines: {
        title: "Return lines",
        index: "Index",
        itemName: "Item name",
        quantity: "Quantity",
        price: "Price",
        type: "Type",
        demand: "Demand",
        reason: "Reason",
        description: "Description",
        files: "Files",
      },
      bankAccountNumber: "Bank account number",
      type: "Type",
      typeReturn: "Return",
      typeComplaint: "Complaint",
      selectReturnType: "Select type",
      referenceNumber: "Reference number",
      approve: "Approve",
      generateReturnLabel: "Generate return label",
      street: "Street",
      city: "City",
      details: "Details",
      return: "Return",
      searchReturns: "Search returns",
      marketPlace: "MarketPlace",
      returnCode: "Return code",
      invoiceNumber: "Invoice number",
      name: "Name",
      surname: "Surname",
      returnCreateDate: "Return created date",
      lastUpdateDoc: "Document last updated",
      approved: "Approved",
      noApproved: "No approved",
      failedDownloadReturns: "Failed when download returns",
      failedDownloadReturnDetails: "Failed when download return details",
      view: "View",
      order: "Order",
      trackNo: "Track number",
      returnTrackNo: "Return track number",
      courierOption: "Courier",
      freeShipping: "Free shipping",
      clubNN: "Club NN",
      courierMessage: "Courier message",
      successReturnApproved: "Return approved",
      successGenerateReturnLabel: "Generated return lab0,el",
      relatedDocuments: "Related documents",
      invoice: "Invoice",
    },
    "Service request details": "Service request details",
    "service request details": "Service request details",
    "Service requests": "Service requests",
    "service requests": "Service requests",
    "Service request list": "Service request list",
    "service request list": "Service request list",
    returns: "Returns",
    "return details": "Return details",
    "returns list": "Returns list",
    "goods release details": "Goods release details",
    "Goods release list": "Goods release list",
    "Goods release": "Goods release",
    "goods release": "Goods release",
    defaultMarketPlace: "Default MarketPlace",
    changePaymentInfoBtn: "Change payment info",
    changeShippingDataBtn: "Change shipping info",
    tickets: "Tickets",
    "ticket details": "Ticket details",
    "tickets list": "Tickets list",
    failedWhenGetFile: "Failed when get file",
    noInvoiceToDownload: "No invoice to download",
    downloadInvoice: "Download Invoice",
    thuliumId: "Thulium id",
    thuliumLogin: "Thulium login",
    allowed: "Allowed",
    deliveryPointNumber: "Delivery point number",
    deliveryPointId: "Delivery point id",
    contactPerson: "Contact person",
    customerCode: "Customer code",
    confirmBrakeLoopGetData:
      "Do you want to finish downloading data and go to another page",
    chooseMarketPlace: "Choose MarketPlace",
    returnToSearch: "Return to search",
    search: "Search",
    docNumber: "Document number",
    cardName: "Card name",
    licTreadNum: "LicTread number",
    noResultFound: "No result found",
    pleaseTryAgainWithDifferentQuery: "Please try again with a different query",
    errorWhenGetSearchData: "Error when get search data",
    searchResults: "Search results",
    docDate: "Document date",
    clientNumber: "Client number",
    clientName: "Client name",
    allMarketPlace: "All MarketPlace",
    relatedDocuments: "Related documents",
    trackNumber: "Track number",
    "Thulium status for user is successfully changed":
      "Thulium status for user is successfully changed",
    "Failed when change Thulium status for user":
      "Failed when change Thulium status for user",
    orderStatus: "Order status",
    "Click here": "Click here",
    docVatSum: "Document vat sum",
    docTotal: "Document total",
    "Document items before correction": "Document items before correction",
    "Document items after correction": "Document items after correction",
    invoices: "Invoices",
    "correction Invoices": "Correction Invoices",
    "Correction invoices list": "Correction invoices list",
    "Invoices list": "Invoices list",
    "Correction document items": "Correction document items",
    "Failed get invoices": "Failed get invoices",
    "Search invoice": "Search invoice",
    "Failed get correction invoices": "Failed get correction invoices",
    "Search correction invoice": "Search correction invoice",
    Delivery: "Delivery",
    Invoice: "Invoice",
    "Correction invoice": "Correction invoice",
    "Invalid date!": "Invalid date!",
    "Deliveries list": "Deliveries list",
    "Search delivery": "Search delivery",
    "Failed get deliveries": "Failed get deliveries",
    delivery: "delivery",
    "Number at card": "Number at card",
    "Card code": "Card code",
    deliveries: "Deliveries",
    "document details": "Document details",
    "Amount vat": "Amount vat",
    "Unit price": "Unit price",
    "Total gross": "Total gross",
    "Total vat": "Total vat",
    Index: "Index",
    "Document items": "Document items",
    "Online payment date": "Online payment date",
    "Online payment Id": "Online payment Id",
    Courier: "Courier",
    header: "Header",
    "Paid sum": "Paid sum",
    "Shipping from": "Shipping from",
    "Delivery name": "Delivery name",
    document: "Dokument",
    "Orders list": "Orders list",
    "Search order": "Search order",
    Download: "Download",
    Yes: "Yes",
    No: "No",
    "Failed get orders": "Failed get orders",
    Amount: "Amount",
    "Released for shipping": "Released for shipping",
    "Start date": "Start date",
    "End date": "End date",
    "Select marketPlace": "Select MarketPlace",
    Overview: "Overview",
    "Payment & Shipping": "Payment & Shipping",
    "Billing address": "Billing address",
    "Shipping address": "Shipping address",
    "Lines in document": "Lines in document",
    order: "order",
    Order: "Order",
    documentNumber: "Document number",
    "Discount amount": "Discount amount",
    "Created date": "Created date",
    "Created time": "Created time",
    "Method payment": "Method payment",
    "Payment Id": "Payment Id",
    "Delivery type": "Delivery type",
    "Released date for shipping": "Released date for shipping",
    "Payment date": "Payment date",
    "Item name": "Name",
    Quantity: "Quantity",
    Price: "Price",
    Total: "Total",
    "Delivery target point": "Delivery target point",
    "Confirmed password": "Confirmed password",
    "NIP/TAX": "NIP/TAX ID",
    "Reference number": "Reference number",
    "Customers list": "Customers list",
    "Failed get customer data": "Failed get customer data",
    "customers list": "Customers list",
    "customer details": "Customer details",
    Details: "Details",
    Documents: "Documents",
    "Failed get customers": "Failed get customers",
    Code: "Code",
    "Search customer": "Search customer",
    "No data found": "No data found",
    "Service orders": "Service orders",
    Orders: "Orders",
    Invoices: "Invoices",
    Deliveries: "Deliveries",
    "Correction invoices": "Correction invoices",
    serviceOrders: "Service orders",
    orders: "Orders",
    correctionInvoices: "Correction invoices",
    Billing: "Billing",
    Shopping: "Shopping",
    Addresses: "Addresses",
    "Company name": "Company name",
    Country: "Country",
    "Post code": "Post code",
    City: "City",
    Street: "Street",
    "Street number": "Street number",
    Block: "Block ",
    Building: "Building",
    "Contact persons": "Contact persons",
    "No name": "No name",
    "First name": "First name",
    Address: "Address",
    "Download data": "Download data",
    Search: "Search",
    "Create date": "Create date",
    Number: "Number",
    "Failed authorization": "Failed authorization",
    "Ok, logout!": "Ok, logout!",
    Active: "Active",
    Users: "Users",
    "Add group": "Add group",
    Select: "Select",
    Remove: "Remove",
    Type: "Type",
    "user details": "User details",
    marketPlace: "Marketplace",
    "Group list": "Group list",
    "Users list": "Users list",
    "User status is successfully changed":
      "User status is successfully changed",
    "Failed when change status user": "Failed when change status user",
    "User admin status is successfully changed":
      "User admin status is successfully changed",
    "Failed when change user admin status":
      "Failed when change user admin status",
    "User moderator status is successfully changed":
      "User moderator status is successfully changed",
    "Failed when change user moderator status":
      "Failed when change user moderator status",
    Update: "Update",
    "Edit details": "Edit details",
    "Full Name": "Full Name",
    "User data is successfully updated": "User data is successfully updated",
    "Failed when updated user": "Failed when updated user",
    "Contact phone": "Contact phone",
    "Failed change user password": "Failed change user password",
    "User group is successfully removed": "User group is successfully removed",
    Group: "Group",
    "Failed when assign group to user": "Failed when assign group to user",
    "Assign group to user is successfully":
      "Assign group to user is successfully",
    "Search user": "Search user",
    Actions: "Actions",
    Loading: "Loading",
    View: "View",
    Info: "Info",
    Groups: "Groups",
    "Failed get users": "Failed get users",
    "User permissions": "User permissions",
    Add: "Add",
    "Please wait": "Please wait",
    "Failed when added users": "Failed when added users",
    "Form has been successfully submitted":
      "Form has been successfully submitted",
    validEmail: "Must be a valid email",
    validRequiredWithName: "{name} is required",
    validRequired: "This field is required",
    validMatchWithName: "{name} must be the same",
    validMinWithNumber: "The minimum number of characters is {number}",
    validMaxNowDateWithName: "{name} cannot be more than today",
    validMinDateWithTwoName: "{name} must be after then {name2}",
    validOneOfElementsRequired: "One of element is required",
    validOnlyNumber: "Must be only digits",
    validPhoneNumber: "Must be correct phone number",
    validRequiredWithNameWhenCheckedName:
      "{name} is required when {name2} is selected",
    "If not selected it will be normal user":
      "If not selected it will be normal user",
    "Add user": "Add user",
    Name: "Name",
    "Last name": "Last name",
    Email: "Email",
    Password: "Password",
    Phone: "Phone",
    Language: "Language",
    Poland: "Poland",
    England: "England",
    Germany: "Germany",
    "Theme Mode": "Theme Mode",
    Light: "Light",
    Dark: "Dark",
    Admin: "Admin",
    Moderator: "Moderator",
    Cancel: "Cancel",
    Submit: "Submit",
    "users list": "Users list",
    "Ok, got it!": "Ok, got it!",
    "Sorry, looks like there are some errors detected, please try again":
      "Sorry, looks like there are some errors detected, please try again.",
    "Password is successfully changed": "Password is successfully changed",
    "After closing the window, the system will log you out":
      "After closing the window, the system will log you out",
    "Password must be at least 8 character and contain symbols":
      "Password must be at least 8 character and contain symbols",
    "Confirm New Password": "Confirm New Password",
    "New Password": "New Password",
    "New Passwords": "New Passwords",
    "Current Password": "Current Password",
    "Update Password": "Update Password",
    passwords: "Passwords",
    cancel: "Cancel",
    moderator: "Moderator",
    changePassword: "Change password",
    phone: "Phone",
    communication: "Communication",
    signInMethod: "Sign-in Method",
    fullName: "Full name",
    profileDetails: "Profile details",
    homePage: "Home",
    admin: "Admin",
    user: "User",
    languages: "Languages",
    accountSettings: "Account Settings",
    signOut: "Sign Out",
    myProfile: "My profile",
    tryAgain: "Try again",
    name: "Name",
    email: "Email",
    pleaseWait: "Please Wait",
    login: "Login",
    forgetPassword: "Forget password",
    password: "Password",
    dashboard: "Dashboard",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign In",
    signUp: "Sign Up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },
  de: {
    customerComponent: {
      releasedForShipping: "Freigegeben zum Versand",
      amount: "Betrag",
    },
    tipTapEditor: {
      inputImagePlaceholder: "URL eingeben",
      footer: "Fusszeile",
    },
    tinyEditor: {
      failedDownloadFooterContent:
        "Fehler beim Herunterladen von Fußzeileninhalt",
      footer: "Fusszeile",
    },
    modalsComponent: {
      changePaymentInfo: {
        title: "Zahlung aktualisieren",
        paymentId: "Zahlungs-ID",
        paymentDate: "Zahlungsdatum",
        paymentType: "Zahlungsart",
        failedDownloadDictionary:
          "Fehler beim Abrufen der Zahlungstyp-Wörterbuchdaten",
        successUpdatedPayment: "Zahlung erfolgreich aktualisiert",
        partialSuccessUpdatedPayment:
          "Die Zahlung wurde erfolgreich aktualisiert, aber der Infokommentar wurde nicht hinzugefügt",
      },
      changeShippingData: {
        title: "Versandinformationen aktualisieren",
        price: "Preis",
        shippingType: "Versandart",
        shippingItem: "Index",
        failedDownloadDictionary: "Fehler beim Abrufen der Wörterbuchdaten",
        successUpdatedShipping: "Versanddaten erfolgreich aktualisiert",
        partialSuccessUpdatedShipping:
          "Versanddaten erfolgreich aktualisiert, aber Infokommentar nicht hinzugefügt",
      },
    },
    uploaderFileComponent: {
      firstTextPart1: "Datei hochladen",
      firstTextPart2: "oder ziehen und ablegen",
      secondText: "PNG, JPG, GIF bis 10MB",
      noName: "Kein Name",
    },
    ticket: {
      addTicketModal: {
        addTicketTitle: "Ticket hinzufügen",
        firstTabTitle: "Details",
        secondTabTitle: "Inhalt & Kommentar",
        thirdTabTitle: "Anhänge",
        customerId: "Kunden-ID",
        queue: "Warteschlange",
        category: "Kategorie",
        status: "Zustand",
        subject: "Betreff",
        directionSendEmail: "E-Mail-Senderichtung",
        behalfOfClient: "Im Auftrag des Kunden",
        toClient: "Zum Kunden",
        source: "Quelle",
        comment: "Kommentar",
        tags: "Tags",
        body: "Körper",
        email: "Email",
        phone: "Telefon",
        sms: "SMS",
        chat: "Chatten",
        website: "Webseite",
        emailTo: "E-Mail an",
        emailFrom: "E-Mail von",
        failedDownloadDictionary:
          "Wörterbuchdaten konnten nicht abgerufen werden",
      },
      addTicketMessageModal: {
        addTicketMessageTitle: "Nachricht hinzufügen",
        typeMessage: "Nachricht eingeben",
        message: "Nachricht",
        comment: "Kommentar",
        bodyText: "Text",
      },
      ticketList: {
        searchTickets: "Tickets suchen",
        selectUser: "Benutzer auswählen",
        selectStatus: "Zustand auswählen",
        selectQueue: "Warteschlange auswählen",
        addTicket: "Status auswählen",
        failedDownloadTickets: "Fehler beim Abrufen von Tickets",
        failedDownloadTicketsConfirmButtonText: "Ok, verstanden!",
        confirmRemoveTicket:
          "Sind Sie sicher, dass Sie Ticket {number} löschen möchten?",
        successfullyRemovedTicket: "Ticket wurde erfolgreich entfernt",
        columnTicketId: "Ticket-ID",
        columnTitle: "Titel",
        columnCount: "Anzahl",
        columnFullStatusName: "Status",
        columnCreatedDate: "Erstellungsdatum",
        columnAssignedUser: "Zugewiesener Benutzer",
      },
      ticketDetail: {
        addMessage: "Nachricht hinzufügen",
        tabMessages: "Nachrichten",
        queue: "Warteschlange",
        emailFrom: "E-Mail von",
        assignedLogin: "Zugewiesener Login",
        lastUpdated: "Zuletzt aktualisiert",
        category: "Kategorie",
        status: "Zustand",
        successfullyDownloadTicket: "Ticket-Download-Daten erfolgreich",
        failedDownloadTicket: "Ticket-Download-Daten fehlgeschlagen",
        successfullyUpdatedTicket: "Ticket erfolgreich aktualisiert",
        failedUpdateTicket: "Aktualisierung des Tickets ist fehlgeschlagen",
        attachmentFileName: "Anhang",
        failedDownloadAttachment: "Download des Anhangs fehlgeschlagen",
      },
    },
    serviceReq: {
      detailsTitle: "Details zur Serviceanfrage",
      searchText: "Serviceanfragen suchen",
      failedDownloadServiceReqs: "Fehler beim Herunterladen von Dienstanfragen",
      failedDownloadServiceReq:
        "Fehler beim Herunterladen der Serviceanforderungsdetails",
      docDate: "Dokumentdatum",
      createDate: "Erstellungsdatum",
      createdTime: "Erstellte Zeit",
      documentNumber: "Dokumentnummer",
      marketPlace: "MarketPlace",
      referenceNumber: "Referenznummer",
      customerCode: "Kundencode",
      clientName: "Kundenname",
      taxNumber: "NIP/TAX",
      datePurchase: "Kaufdatum",
      relatedDocuments: "Verwandte Dokumente",
      header: "Header",
      lines: "Zeilen im Dokument",
      order: "Bestellung",
      delivery: "Lieferung",
      invoice: "Rechnung",
      serviceOrders: "Serviceanfragen",
      serviceRequestNumber: "Serviceanfragenummer",
      trackNo: "Titelnummer",
      courier: "Kurier",
      status: "Status",
      linesTable: {
        index: "Index",
        itemName: "Artikelname",
        quantity: "Menge",
        description: "Beschreibung",
        causeComplaint: "Grund zur Beanstandung",
        solution: "Lösung",
        decision: "Entscheidung",
        status: "Status",
        typeReport: "Art des Berichts",
        customerRequest: "Kundenanfrage",
      },
      comment: {
        failedDownloadComments: "Kommentare beim Herunterladen fehlgeschlagen",
        commentsTitle: "Kommentare",
        addCommentTitle: "„Kommentar hinzufügen",
        addCommentBtn: "„Kommentar hinzufügen",
        userEmail: "Email",
        createdDate: "Erstellungsdatum",
        commentContent: "Inhalt kommentieren",
        confirmRemove: "Sind Sie sicher, dass Sie Kommentare löschen möchten?",
        successfullyRemoved: "Kommentar wurde erfolgreich entfernt",
      },
    },
    global: {
      light: "Hell",
      dark: "Dunkel",
      lang: {
        english: "Englisch",
        german: "Deutsch",
        polish: "Polnisch",
      },
      clickHere: "Klicken Sie hier",
      search: "Suchen",
      select: "Wählen",
      add: "Hinzufügen",
      send: "Senden",
      update: "Aktualisieren",
      cancel: "Abbrechen",
      canceled: "Abgesagt",
      back: "Zurück",
      pleaseWait: "Bitte warten",
      next: "Weiter",
      yes: "Ja",
      no: "Nein",
      download: "Herunterladen",
      noDataFound: "Keine Daten gefunden",
      from: "Von",
      view: "Ansicht",
      remove: "Entfernen",
      loading: "Laden",
      confirmBrakeLoopGetData:
        "Möchten Sie das Herunterladen der Daten abschließen und zu einer anderen Seite gehen?",
      actions: "Aktionen",
      startDate: "Startdatum",
      endDate: "Enddatum",
      invalidDate: "Okay, verstanden!",
      saveChanges: "Änderungen speichern",
      successfullySendFromPopup: "Formular wurde erfolgreich gesendet",
      failedTextPopup:
        "Entschuldigung, es sieht so aus, als ob einige Fehler entdeckt wurden, bitte versuchen Sie es erneut",
      confirmButtonTextPopup: "Ok, got it!",
      formValid: {
        validEmail: "Muss eine gültige E-Mail sein",
        validRequiredWithName: "{name} ist erforderlich",
        validExistInList: "Dieser Wert existiert in der Liste",
        validRequired: "Dieses Feld ist erforderlich",
        validMatchWithName: "{name} muss gleich sein",
        validMinWithNumber: "Die Mindestanzahl an Zeichen ist {number}",
        validMaxNowDateWithName: "{name} darf nicht nach heute liegen",
        validMinDateWithTwoName: "{name} muss nach {name2} sein",
        validOneOfElementsRequired: "Einer der Artikel ist erforderlich",
        validOnlyNumber: "Müssen nur Ziffern sein",
        validPhoneNumber: "MMuss korrekte Telefonnummer sein",
        validRequiredWithNameWhenCheckedName:
          "{name} ist erforderlich, wenn {name2} ausgewählt ist",
        validRequiredOrInvalidDate: "Pflichtfeld oder ungültiges Datum",
        validPasswordCharacters:
          "Das Passwort muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen",
      },
    },
    documentComponent: {
      comment: {
        failedDownloadComments: "Kommentare zum Herunterladen fehlgeschlagen",
        commentsTitle: "Kommentare",
        addCommentTitle: "Kommentar hinzufügen",
        addCommentBtn: "Kommentar hinzufügen",
        userEmail: "Email",
        createdDate: "Erstellungsdatum",
        commentContent: "Inhalt kommentieren",
        confirmRemove: "Sind Sie sicher, dass Sie Kommentare löschen möchten ?",
        successfullyRemoved: "Kommentar wurde erfolgreich entfernt",
      },
      headInfo: {
        confirmCancelDoc:
          "Sind Sie sicher, dass Sie dieses Dokument stornieren möchten?",
        successfullyCanceledDoc: "Dokument wurde erfolgreich storniert",
        partialSuccessfullyCanceledDoc:
          "Dokument wurde erfolgreich storniert, aber Infokommentar nicht hinzugefügt",
        cancelDocBtn: "Dokument stornieren",
        orderIsCanceled: "Bestellung wurde storniert !!!",
      },
    },
    dashboardComponent: {
      OrdersLast1Days: "Gestern",
      OrdersLast0Days: "Heute",
      Highlights30Days: "Letzte 30 Tage vorschlagen",
      Highlights7Days: "Letzte 7 Tage vorschlagen",
      OpenRelesedProcentLast30Days:
        "In den letzten 30 Tagen zum Versand freigegeben",
      PackedAndShipedPercentLast30Days:
        "Verpackt und versendet in den letzten 30 Tagen",
      OpenRelesedProcentLast7Days:
        "In den letzten 7 Tagen zum Versand freigegeben",
      PackedAndShipedPercentLast7Days:
        "Verpackt und versendet in den letzten 7 Tagen",
      failedDownloadStatisticalData:
        "Fehler beim Abrufen statistischer Daten von der SAPI",
    },
    items: {
      itemQuantityListForm: {
        lineName: "Name",
        lineIndex: "Index",
        lineQuantity: "Menge",
        failedDownloadItems: "Produktliste konnte nicht abgerufen werden",
      },
    },
    goodsRelease: {
      addDraftDocModal: {
        comment: "Kommentar",
        title: "Neuen Entwurf des Warenfreigabedokuments hinzufügen",
        itemsList: "Artikelliste",
        itemsListInfo: "Ein Element ist erforderlich",
      },
      createdDate: "Erstellungsdatum",
      searchDoc: "Dokumente durchsuchen",
      createdDocument: "Erstelltes Dokument",
      failedDownloadReleaseDoc: "Fehler beim Herunterladen von Dokumenten",
      draftDocEntry: "Entwurf DocEntry",
      docEntry: "DocEntry",
      comment: "Kommentar",
      draftDocNum: "Nummer des Entwurfsdokuments",
      docNum: "Dokumentnummer",
      draftLine: "Warenfreigabezeilen entwerfen",
      releasedLine: "Warenfreigabezeilen",
      failedDownloadReleasedDetails:
        "Fehler beim Herunterladen von freigegebenen Details",
      failedDownloadItems: "Fehler beim Herunterladen von Elementen",
      addDraftDoc: "Entwurfsdokument hinzufügen",
      lineName: "Name",
      lineIndex: "Index",
      lineQuantity: "Menge",
    },
    returnsView: {
      lines: {
        title: "Rückleitungen",
        index: "Index",
        itemName: "Artikelname",
        quantity: "Menge",
        price: "Preis",
        type: "Typ",
        demand: "Anforderung",
        reason: "Grund",
        description: "Beschreibung",
        files: "Dateien",
      },
      bankAccountNumber: "Bankkontonummer",
      type: "Typ",
      typeReturn: "Rückgabe",
      typeComplaint: "Beschwerde",
      selectReturnType: "Typ auswählen",
      referenceNumber: "Referenznummer",
      approve: "Genehmigen",
      generateReturnLabel: "Rücksendeetikett generieren",
      street: "Straße",
      city: "Stadt",
      details: "Details",
      return: "Zurück",
      searchReturns: "Ergebnisse der Suche",
      marketPlace: "MarketPlace",
      returnCode: "Rückgabecode",
      invoiceNumber: "Rechnungsnummer",
      name: "Name",
      surname: "Nachname",
      returnCreateDate: "Erstellungsdatum zurückgeben",
      lastUpdateDoc: "Dokument zuletzt aktualisiert",
      approved: "Genehmigt",
      noApproved: "Nicht genehmigt",
      failedDownloadReturns: "Fehler bei Rückgabe des Downloads",
      failedDownloadReturnDetails:
        "Fehler beim Herunterladen von Rückgabedetails",
      view: "Ansicht",
      order: "Bestellen",
      trackNo: "Titelnummer",
      returnTrackNo: "Rücksendeverfolgungsnummer",
      courierOption: "Kurier",
      freeShipping: "Kostenloser Versand",
      clubNN: "Club NN",
      courierMessage: "Kuriernachricht",
      successReturnApproved: "Rücksendung genehmigt",
      successGenerateReturnLabel: "Erstelltes Rücksendeetikett",
      relatedDocuments: "Zugehörige Dokumente",
      invoice: "Rechnungen",
    },
    "Service request details": "Details zur Serviceanfrage",
    "service request details": "Details zur Serviceanfrage",
    "Service requests": "Serviceanfragen",
    "service requests": "Serviceanfragen",
    "Service request list": "Service Request List",
    "service request list": "Service Request List",
    returns: "Rückgaben",
    "return details": "Rücksendedetails",
    "returns list": "Retourenliste",
    "goods release details": "Warenfreigabedetails",
    "Goods release list": "Warenfreigabeliste",
    "Goods release": "Warenfreigabe",
    "goods release": "Warenfreigabe",
    defaultMarketPlace: "Standard Marktplatz",
    changePaymentInfoBtn: "Zahlungsinformationen ändern",
    changeShippingDataBtn: "Versandinformationen andern",
    tickets: "Eintrittskarten",
    "ticket details": "Ticketdetails",
    "tickets list": "Ticketliste",
    failedWhenGetFile: "Fehler beim Abrufen der Datei",
    noInvoiceToDownload: "Keine Rechnung zum Herunterladen",
    downloadInvoice: "Rechnung herunterladen",
    thuliumId: "Thulium ID",
    thuliumLogin: "Thulium Login",
    allowed: "Erlaubt",
    deliveryPointNumber: "Lieferpunktnummer",
    deliveryPointId: "Lieferpunkt-ID",
    contactPerson: "Ansprechpartner",
    customerCode: "Kundencode",
    confirmBrakeLoopGetData:
      "Möchten Sie das Herunterladen der Daten beenden und zu einer anderen Seite gehen",
    chooseMarketPlace: "MarketPlace auswählen",
    returnToSearch: "Zurück zur Suche",
    search: "Suche",
    docNumber: "Dokumentnummer",
    cardName: "Kartenname",
    noResultFound: "Kein Ergebnis gefunden",
    pleaseTryAgainWithDifferentQuery:
      "Bitte versuchen Sie es erneut mit einer anderen Abfrage",
    errorWhenGetSearchData: "Fehler beim Abrufen der Suchdaten",
    searchResults: "Suchergebnisse",
    docDate: "Dokumentdatum",
    clientNumber: "Auftragnehmernummer",
    clientName: "Name des Auftragnehmers",
    allMarketPlace: "Alle MarketPlace",
    relatedDocuments: "Zugehörige Dokumente",
    trackNumber: "Frachtbriefnummer",
    "Thulium status for user is successfully changed":
      "Der Thulium-Status durch den Benutzer wurde erfolgreich",
    "Failed when change Thulium status for user":
      "Fehler beim Ändern des Thulium-Status durch Benutzer",
    orderStatus: "Fortschrittsstatus",
    "Click here": "Klicken Sie hier",
    docVatSum: "Summe MwSt",
    docTotal: "Dokument insgesamt",
    "Document items before correction": "Belegpositionen vor Korrektur",
    "Document items after correction": "Belegpositionen nach Korrektur",
    invoices: "Rechnungen",
    "correction Invoices": "Korrekturrechnungen",
    "Correction invoices list": "Korrekturrechnungsliste",
    "Invoices list": "Rechnungsliste",
    "Correction document items": "Korrekturbelegpositionen",
    "Failed get invoices": "Rechnungen erhalten fehlgeschlagen",
    "Search invoice": "Rechnung suchen",
    "Failed get correction invoices":
      "Korrekturrechnungen erhalten fehlgeschlagen",
    "Search correction invoice": "Korrekturrechnung suchen",
    Delivery: "Lieferung",
    Invoice: "Rechnung",
    "Correction invoice": "Korrekturrechnung",
    "Invalid date!": "Ungültiges Datum!",
    "Deliveries list": "Lieferliste",
    "Search delivery": "Lieferung suchen",
    "Failed get deliveries": "Lieferungen fehlgeschlagen",
    delivery: "lieferung",
    "Number at card": "Nummer auf Karte",
    "Card code": "Kartencode",
    deliveries: "Lieferungen",
    "document details": "Dokumentdetails",
    "Amount vat": "MwSt.-Betrag",
    "Unit price": "Stückpreis",
    "Total gross": "Gesamtbrutto",
    "Total vat": "Gesamt-MwSt",
    Index: "Index",
    "Document items": "Dokumentenpositionen",
    "Online payment date": "Online-Zahlungsdatum",
    "Online payment Id": "Online-Zahlungs-Id",
    Courier: "Kurierin",
    header: "Header",
    "Paid sum": "Bezahlte Summe",
    "Shipping from": "Versand von",
    "Delivery name": "Liefername",
    document: "Dokument",
    "Orders list": "Bestellliste",
    "Search order": "Suchauftrag",
    Download: "Herunterladen",
    Yes: "Ja",
    No: "Nein",
    "Failed get orders": "Bestellungen fehlgeschlagen",
    Amount: "Betrag",
    "Released for shipping": "Freigegeben zum Versand",
    "Start date": "Startdatum",
    "End date": "Enddatum",
    "Select marketPlace": "MarketPlace auswählen",
    Overview: "Übersicht",
    "Payment & Shipping": "Zahlung & Versand",
    "Billing address": "Rechnungsadresse",
    "Shipping address": "Lieferadresse",
    "Lines in document": "Zeilen im Dokument",
    order: "bestellen",
    Order: "Bestellen",
    documentNumber: "Dokument nummerieren",
    "Discount amount": "Rabattbetrag",
    "Created date": "Erstellungsdatum",
    "Created time": "Erstellungszeit",
    "Method payment": "Zahlungsmethode",
    "Payment Id": "Zahlungs-ID",
    "Delivery type": "Lieferart",
    "Released date for shipping": "Datum der Versandfreigabe",
    "Payment date": "Zahlungsdatum",
    "Item name": "Artikelname",
    Quantity: "Menge",
    Price: "Preis",
    Total: "Gesamt",
    "Delivery target point": "Lieferzielpunkt",
    "Confirmed password": "Bestätigtes passwor",
    "NIP/TAX": "NIP/TAX ID",
    "Reference number": "Referenznummer",
    "Customers list": "Kundenliste",
    "Failed get customer data": "Abrufen der Kundendaten fehlgeschlagen",
    "customers list": "Kundenliste",
    "customer details": "Kundendaten",
    Details: "Details",
    Documents: "Dokumente",
    "Failed get customers": "Kunden gewinnen fehlgeschlagen",
    Code: "Code",
    "Search customer": "Kunde suchen",
    "No data found": "Keine Daten gefunden",
    Orders: "Bestellungen",
    Invoices: "Rechnungen",
    Deliveries: "Lieferungen",
    "Service orders": "Serviceaufträge",
    "Correction invoices": "Korrekturrechnungen",
    serviceOrders: "Serviceaufträge",
    orders: "Bestellungen",
    correctionInvoices: "Korrekturrechnungen",
    Billing: "Abrechnung",
    Shopping: "Einkaufen",
    Addresses: "Adressen",
    "Company name": "Firmenname",
    Country: "Land",
    "Post code": "Postleitzahl",
    City: "Stadt",
    Street: "Straße",
    "Street number": "Hausnummer",
    Block: "Blockieren ",
    Building: "Gebäude",
    "Contact persons": "Ansprechpartner",
    "No name": "Kein Name",
    "First name": "Vorname",
    Address: "Adresse",
    "Download data": "Daten herunterladen",
    Search: "Suche",
    "Create date": "Erstellungsdatum",
    Number: "Nummer",
    "Failed authorization": "Autorisierung fehlgeschlagen",
    "Ok, logout!": "Ok, abmelden!",
    Active: "Aktiv",
    Users: "Benutzer",
    "Add group": "Gruppe hinzufügen",
    Select: "Auswählen",
    Remove: "Entfernen",
    Type: "Typ",
    "user details": "Benutzerdetails",
    marketPlace: "MarketPlace",
    "Group list": "Gruppenliste",
    "Users list": "Benutzerliste",
    "User status is successfully changed":
      "Benutzerstatus wurde erfolgreich geändert",
    "Failed when change status user": "Fehler beim Ändern des Benutzerstatus",
    "User admin status is successfully changed":
      "Benutzer-Admin-Status wurde erfolgreich geändert",
    "Failed when change user admin status":
      "Fehler beim Ändern des Benutzer-Admin-Status",
    "User moderator status is successfully changed":
      "Status des Benutzermoderators wurde erfolgreich geändert",
    "Failed when change user moderator status":
      "Fehler beim Ändern des Benutzermoderatorstatus",
    Update: "Aktualisieren",
    "Edit details": "Details bearbeiten",
    "Full Name": "Vollständiger Name",
    "User data is successfully updated":
      "Benutzerdaten wurden erfolgreich aktualisiert",
    "Failed when updated user": "Fehler bei Benutzeraktualisierung",
    "Contact phone": "Telefon kontaktieren",
    "Failed change user password": "Benutzerpasswort ändern fehlgeschlagen",
    "User group is successfully changed":
      "Benutzergruppe wurde erfolgreich entfernt",
    Group: "Gruppe",
    "Failed when assign group to user":
      "Fehler beim Zuweisen einer Gruppe zu einem Benutzer",
    "Assign group to user is successfully":
      "Gruppe einem Benutzer zuweisen war erfolgreich",
    "Search user": "Benutzer suchen",
    "User group is successfully removed":
      "Die Benutzergruppe wurde erfolgreich entfernt",
    Actions: "Aktionen",
    Loading: "Laden",
    View: "Ansicht",
    Info: "Infos",
    Groups: "Gruppen",
    "Failed get users": "Benutzer abrufen fehlgeschlagen",
    "User permissions": "Benutzerberechtigungen",
    Add: "Hinzufügen",
    "Please wait": "Bitte warten",
    "Failed when added users": "Fehler beim Hinzufügen von Benutzern",
    "Form has been successfully submitted":
      "Formular wurde erfolgreich übermittelt",
    validEmail: "Muss eine gültige E-Mail sein",
    validRequiredWithName: "{name} ist erforderlich",
    validRequired: "Dieses Feld ist erforderlich",
    validMatchWithName: "{name} muss gleich sein",
    validMinWithNumber: "Die Mindestanzahl an Zeichen ist {number}",
    validMaxNowDateWithName: "{name} darf nicht nach heute liegen",
    validMinDateWithTwoName: "{name} muss nach {name2} sein",
    validOneOfElementsRequired: "Einer der Artikel ist erforderlich",
    validOnlyNumber: "Müssen nur Ziffern sein",
    validPhoneNumber: "MMuss korrekte Telefonnummer sein",
    validRequiredWithNameWhenCheckedName:
      "{name} ist erforderlich, wenn {name2} ausgewählt ist",
    "Add user": "Nutzer hinzufügen",
    Name: "Name",
    "Last name": "Nachname",
    Email: "Email",
    Password: "Passwort",
    Phone: "Telefon",
    Language: "Sprache",
    Poland: "Polen",
    England: "England",
    Germany: "Deutschland",
    "Theme Mode": "Themenmodus",
    Light: "Licht",
    Dark: "Dunkel",
    Admin: "Admin",
    Moderator: "Moderator",
    Cancel: "Abbrechen",
    Submit: "Absenden",
    "If not selected it will be normal user":
      "Wenn diese Option nicht ausgewählt ist, handelt es sich um einen normalen Benutzer",
    "users list": "Users list",
    "Ok, got it!": "Ok, habe es!",
    "Sorry, looks like there are some errors detected, please try again":
      "Entschuldigung, es sieht so aus, als ob einige Fehler festgestellt werden. Bitte versuchen Sie es erneut",
    "Password is successfully changed": "Passwort wurde erfolgreich geändert",
    "After closing the window, the system will log you out":
      "Nach dem Schließen des Fensters werden Sie vom System abgemeldet",
    "Password must be at least 8 character and contain symbols":
      "Passwort muss mindestens 8 Zeichen lang sein und Symbole enthalten",
    "Confirm New Password": "Neues Passwort bestätigen",
    "New Password": "Neues Passwort",
    "New Passwords": "Neue Passwörter",
    "Current Password": "Aktuelles Passwort",
    "Update Password": "Passwort aktualisieren",
    passwords: "Passwörter",
    cancel: "Abbrechen",
    moderator: "Moderator",
    changePassword: "Passwort ändern",
    phone: "Telefon",
    communication: "Kommunikation",
    signInMethod: "Anmeldemethode",
    fullName: "Vollständiger Name",
    profileDetails: "Profildetails",
    homePage: "Startseite",
    admin: "Administrator",
    user: "Benutzer",
    languages: "Sprachen",
    accountSettings: "Account Einstellungen",
    signOut: "Ausloggen",
    myProfile: "Mein Profil",
    tryAgain: "Versuchen Sie es nochmal",
    name: "Name",
    email: "Email",
    pleaseWait: "Warten Sie mal",
    login: "Anmeldung",
    forgetPassword: "Passwort vergessen",
    password: "Passwort",
    dashboard: "Instrumententafel",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    multiStepSignUp: "Multi-Steps-Anmeldung",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  pl: {
    customerComponent: {
      releasedForShipping: "Zwolnione do wysyłki",
      amount: "Kwota",
    },
    tipTapEditor: {
      inputImagePlaceholder: "Podaj url",
      footer: "Stopka",
    },
    tinyEditor: {
      failedDownloadFooterContent: "Błąd podczas pobierania zawartości stopki",
      footer: "Stopka",
    },
    modalsComponent: {
      changePaymentInfo: {
        title: "Aktualizuj płatność",
        paymentId: "Identyfikator płatności",
        paymentDate: "Identyfikator płatności",
        paymentType: "Typ płatności",
        failedDownloadDictionary:
          "Nie udało się pobrać danych ze słownika typów płatności",
        successUpdatedPayment: "Pomyślnie zaktualizowano płatność",
        partialSuccessUpdatedPayment:
          "Pomyslnie zaktualizowano płatność, ale komentarz informacyjny nie został dodany",
      },
      changeShippingData: {
        title: "Zaktualizuj informacje o wysyłce",
        price: "Cena",
        shippingType: "Typ wysyłki",
        shippingItem: "Indeks",
        failedDownloadDictionary: "Błąd podczas pobierania danych ze słownika",
        successUpdatedShipping: "Pomyślnie zaktualizowano dane wysyłki",
        partialSuccessUpdatedShipping:
          "Pomyślnie zaktualizowano dane wysyłki, ale komentarz informacyjny nie został dodany",
      },
    },
    uploaderFileComponent: {
      firstTextPart1: "Prześlij plik",
      firstTextPart2: "lub przeciągnij i upuść",
      secondText: "PNG, JPG, GIF do 10MB",
      noName: "Brak nazwy",
    },
    ticket: {
      addTicketModal: {
        addTicketTitle: "Dodaj zgłoszenie",
        firstTabTitle: "Szczegóły",
        secondTabTitle: "Wiadomosć i komentarz",
        thirdTabTitle: "Załączniki",
        customerId: "Identyfikator klienta",
        queue: "Kolejka",
        category: "Kategoria",
        status: "Stan",
        subject: "Temat",
        directionSendEmail: "Kierunek wysyłania wiadomości e-mail",
        behalfOfClient: "W imieniu klienta",
        toClient: "Do klienta",
        source: "Źródło",
        comment: "Komentarz",
        tags: "Tagi",
        body: "Treść wiadomości",
        email: "Email",
        phone: "Telefon",
        sms: "SMS",
        chat: "Czat",
        website: "Strona internetowa",
        emailTo: "Email do",
        emailFrom: "Email od",
        failedDownloadDictionary: "Niepowodzenie pobierania danych ze słownika",
      },
      addTicketMessageModal: {
        addTicketMessageTitle: "Dodaj wiadomość",
        typeMessage: "Typ wiadomości ",
        message: "Wiadomość",
        comment: "Komentarz",
        bodyText: "Tekst",
      },
      ticketList: {
        searchTickets: "Wyszukaj zgłoszenia",
        selectUser: "Wybierz użytkownika",
        selectStatus: "Wybierz stan",
        selectQueue: "Wybierz kolejkę",
        addTicket: "Dodaj zgłoszenie",
        failedDownloadTickets: "Nie udało się pobrać zgłoszeń",
        failedDownloadTicketsConfirmButtonText: "Ok, rozumiem!",
        confirmRemoveTicket: "Czy na pewno chcesz usunąć zgłoszenie {numer}",
        successfullyRemovedTicket: "Zgłoszenie zostało pomyślnie usunięte",
        columnTicketId: "Identyfikator zgłoszenia",
        columnTitle: "Tytuł",
        columnCount: "Ilość",
        columnFullStatusName: "Status",
        columnCreatedDate: "Data utworzenia",
        columnAssignedUser: "Przypisany użytkownik",
      },
      ticketDetail: {
        addMessage: "Dodaj wiadomość",
        tabMessages: "Wiadomości",
        queue: "Kolejka",
        emailFrom: "Email od",
        assignedLogin: "Przypisany login",
        lastUpdated: "Ostatnia aktualizacja",
        category: "Kategoria",
        status: "Stan",
        successfullyDownloadTicket: "Pobrano dane zgłoszenia pomyślnie",
        failedDownloadTicket: "Pobranie danych zgłoszenia nie powiodło się",
        successfullyUpdatedTicket: "Pomyślnie zaktualizowano zgłoszenie",
        failedUpdateTicket: "Aktualizacja zgłoszenia nie powiodła się",
        attachmentFileName: "Załącznik",
        failedDownloadAttachment: "Pobranie załącznika nie powiodło się",
      },
    },
    serviceReq: {
      detailsTitle: "Szczegóły zgłoszenia serwisowego",
      searchText: "Wyszukaj zgłoszenie serwisowe",
      failedDownloadServiceReqs: "Błąd podczas pobierania zgłoszeń serwisowych",
      failedDownloadServiceReq:
        "Błąd podczas pobierania szczegółów zgłoszenia serwisowego",
      docDate: "Data dokumentu",
      createDate: "Data utworzenia",
      createdTime: "Czas utworzenia",
      documentNumber: "Numer dokumentu",
      marketPlace: "MarketPlace",
      referenceNumber: "Numer referencyjny",
      customerCode: "Kod klienta",
      clientName: "Nazwa klienta",
      taxNumber: "NIP/TAX",
      datePurchase: "Data zakupu",
      relatedDocuments: "Dokumenty powiązane",
      header: "Nagłówek",
      lines: "Pozycje",
      order: "Zamówienie",
      delivery: "Dostawa",
      invoice: "Faktura",
      serviceOrders: "Zlecenia serwisowe",
      serviceRequestNumber: "Numer zgłoszenia",
      status: "Status",
      trackNo: "Numer przesyłki",
      courier: "Kurier",
      linesTable: {
        index: "Indeks",
        itemName: "Nazwa produktu",
        quantity: "Ilość",
        description: "Opis",
        causeComplaint: "Powód reklamacji",
        solution: "Rozwiazanie problemu",
        decision: "Decyzja",
        status: "Status",
        typeReport: "Rodzaj zgłoszenia",
        customerRequest: "Żądanie klienta",
      },
      comment: {
        failedDownloadComments: "Nieudane pobieranie komentarzy",
        commentsTitle: "Komentarze",
        addCommentTitle: "Dodaj komentarz",
        addCommentBtn: "Dodaj komentarz",
        userEmail: "Email",
        createdDate: "Data utworzenia",
        commentContent: "Treść komentarza",
        confirmRemove: "Czy na pewno chcesz usunąć komentarze?",
        successfullyRemoved: "Komentarz został pomyślnie usunięty",
      },
    },
    global: {
      light: "Jasny",
      dark: "Ciemny",
      lang: {
        english: "Angielski",
        german: "Niemiecki",
        polish: "Polski",
      },
      clickHere: "Kliknij tutaj",
      search: "Szukaj",
      select: "Wybierz",
      add: "Dodaj",
      send: "Wyślij",
      update: "Aktualizuj",
      cancel: "Anuluj",
      canceled: "Anulowany",
      back: "Wstecz",
      pleaseWait: "Proszę czekać",
      next: "Dalej",
      yes: "Tak",
      no: "Nie",
      download: "Pobierz",
      noDataFound: "Nie znaleziono danych",
      from: "Od",
      view: "Podgląd",
      remove: "Usuń",
      loading: "Ładowanie",
      confirmBrakeLoopGetData:
        "Czy chcesz zakończyć pobieranie danych i przejść do innej strony?",
      actions: "Akcje",
      startDate: "Data rozpoczęcia",
      endDate: "Data rozpoczęcia",
      invalidDate: "Niepoprawna data!",
      saveChanges: "Zapisz zmiany",
      successfullySendFromPopup: "Formularz został przesłany pomyślnie",
      failedTextPopup:
        "Przepraszamy, wygląda na to, że wykryto błędy, spróbuj ponownie",
      confirmButtonTextPopup: "Ok, rozumiem!",
      formValid: {
        validEmail: "Musi być prawidłowym adresem e-mail",
        validRequiredWithName: "{name} jest wymagane",
        validExistInList: "Ta wartość istnieje już na liście",
        validRequired: "To pole jest wymagane",
        validMatchWithName: "{name} muszą być takie same",
        validMinWithNumber: "Minimalna ilość znaków to {number}",
        validMaxNowDateWithName: "{name} nie może być późniejsza niż dzisiaj",
        validMinDateWithTwoName: "{name} musi być późniejsza niż {name2}",
        validOneOfElementsRequired: "Wymagany jest jeden z elementów",
        validOnlyNumber: "Muszą być tylko cyfry",
        validPhoneNumber: "Musi być poprawny numer telefonu",
        validRequiredWithNameWhenCheckedName:
          "{name} jest wymagane, gdy wybrano {name2}",
        validRequiredOrInvalidDate: "Wymagane pole lub nieprawidłowa data",
        validPasswordCharacters:
          "Hasło musi zawierać 8 znaków, jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny",
      },
    },
    documentComponent: {
      comment: {
        failedDownloadComments: "Nieudane pobieranie komentarzy",
        commentsTitle: "Komentarze",
        addCommentTitle: "Dodaj komentarz",
        addCommentBtn: "Dodaj komentarz",
        userEmail: "Email",
        createdDate: "Data utworzenia",
        commentContent: "Treść komentarza",
        confirmRemove: "Czy na pewno chcesz usunąć komentarze?",
        successfullyRemoved: "Komentarz został pomyślnie usunięty",
      },
      headInfo: {
        confirmCancelDoc: "Czy na pewno chcesz anulować ten dokument?",
        successfullyCanceledDoc: "Dokument został pomyślnie anulowany",
        partialSuccessfullyCanceledDoc:
          "Dokument został pomyślnie anulowany, ale komentarz informacyjny nie został dodany",
        cancelDocBtn: "Anuluj dokument",
        orderIsCanceled: "Zamówienie zostało anulowane !!!",
      },
    },
    dashboardComponent: {
      OrdersLast1Days: "Wczorajszy Dzień",
      OrdersLast0Days: "Dzisiaj",
      Highlights30Days: "Podsumowanie ostatnie 30 dni",
      Highlights7Days: "Podsumowanie ostatnie 7 dni",
      OpenRelesedProcentLast30Days: "Zwolnione do wysyłki ostatnie 30 dni",
      PackedAndShipedPercentLast30Days: "Spakowane i wysłane ostatnie 30 dni",
      OpenRelesedProcentLast7Days: "Zwolnione do wysyłki ostatnie 7 dni",
      PackedAndShipedPercentLast7Days: "Spakowane i wysłane ostatnie 7 dni",
      failedDownloadStatisticalData:
        "Nie udało się pobrać danych statystycznych z SAPI ",
    },
    items: {
      itemQuantityListForm: {
        lineName: "Nazwa",
        lineIndex: "Indeks",
        lineQuantity: "Ilość",
        failedDownloadItems: "Niepowodzenie podczas pobierania listy produktów",
      },
    },
    goodsRelease: {
      addDraftDocModal: {
        comment: "Komentarz",
        title: "Dodaj nowy roboczy dokumnet RW - wydania",
        itemsList: "Lista produktów",
        itemsListInfo: "Wymagany jest jeden element",
      },
      createdDate: "Data utworzenia",
      searchDoc: "Szukaj dokumentów",
      createdDocument: "Utworzono dokumnet",
      failedDownloadReleaseDoc: "Błąd podczas pobierania dokumentu",
      draftDocEntry: "Roboczy DocEntry",
      docEntry: "DocEntry",
      comment: "Komentarz",
      draftDocNum: "Roboczy numer dokumentu",
      docNum: "Numer dokumentu",
      draftLine: "Robocze lini RW - wydania",
      releasedLine: "Linie RW - wydania",
      failedDownloadReleasedDetails:
        "Niepowodzenie podczas pobierania szczegółów",
      failedDownloadItems: "Niepowodzenie podczas pobierania produktów",
      addDraftDoc: "Dodaj wersję roboczą dokumentu",
      lineName: "Nazwa",
      lineIndex: "Indeks",
      lineQuantity: "Ilość",
    },
    returnsView: {
      lines: {
        title: "Linie zwrotu",
        index: "Indeks",
        itemName: "Nazwa produktu",
        quantity: "Ilość",
        price: "Cena",
        type: "Typ",
        demand: "Żądanie",
        reason: "Powód",
        description: "Opis",
        files: "Pliki",
      },
      bankAccountNumber: "Numer konta bankowego",
      type: "Typ",
      typeReturn: "Zwrot",
      typeComplaint: "Reklamacja",
      selectReturnType: "Wybierz typ",
      referenceNumber: "Numer referencyjny",
      approve: "Zatwierdz",
      generateReturnLabel: "Generuj etykietę zwrotną",
      street: "Ulica",
      city: "Miasto",
      details: "Szczegóły",
      return: "Zwrot",
      searchReturns: "Wyszukaj zwroty",
      marketPlace: "MarketPlace",
      returnCode: "Kod zwrotu",
      invoiceNumber: "Numer faktury",
      fullName: "Pełna nazwa",
      name: "Imię",
      surname: "Nazwisko",
      returnCreateDate: "Data utworzenie zwrotu",
      lastUpdateDoc: "Ostatnia aktualizacja dokumentu",
      approved: "Zatwierdzony",
      noApproved: "Niezatwierdzony",
      failedDownloadReturns: "Niepowodzenie przy pobieraniu zwrotu",
      failedDownloadReturnDetails:
        "Niepowodzenie przy pobieraniu szczegółów zwrotu",
      order: "Zamówienie",
      trackNo: "Numer przesyłki",
      returnTrackNo: "Numer przesyłki zwrotnej",
      courierOption: "Kurier",
      freeShipping: "Darmowa dostawa",
      clubNN: "Klub NN",
      courierMessage: "Wiadomość kurierska",
      successReturnApproved: "Zwrot zatwierdzony",
      successGenerateReturnLabel: "Wynegerowano etykietę zwrotną",
      relatedDocuments: "Dokumenty powiązane",
      invoice: "Faktura",
    },
    "Service request details": "Szczegóły zgłoszenia serwisowego",
    "service request details": "Szczegóły zgłoszenia serwisowego",
    "Service requests": "Zgłoszenia serwisowe",
    "service requests": "Zgłoszenia serwisowe",
    "Service request list": "Lista zgłoszeń serwisowych",
    "service request list": "Lista zgłoszeń serwisowych",
    returns: "Zwroty",
    "return details": "Szczegóły zwrotu",
    "returns list": "Lista zwrotów",
    "goods release details": "RW - szczegóły wydania",
    "Goods release list": "RW - lista wydań",
    "Goods release": "RW - wydanie",
    "goods release": "RW - wydanie",
    defaultMarketPlace: "Domyślny MarketPlace",
    changePaymentInfoBtn: "Zmień informacje o płatności",
    changeShippingDataBtn: "Zmień informacje o wysyłce",
    tickets: "Zgłoszenia",
    "ticket details": "Szczegóły zgłoszenia",
    "tickets list": "Lista zgłoszeń",
    "Tickets list": "Lista zgłoszeń",
    failedWhenGetFile: "Błąd podczas pobierania pliku",
    noInvoiceToDownload: "Brak faktury do pobrania",
    downloadInvoice: "Pobierz fakturę",
    thuliumId: "Thulium idnetyfikator",
    thuliumLogin: "Thulium nazwa",
    allowed: "Dozwolone",
    deliveryPointNumber: "Numer punktu dostawy",
    deliveryPointId: "Identyfikator punktu dostawy",
    contactPerson: "Osoba kontaktowa",
    customerCode: "Kod klienta",
    confirmBrakeLoopGetData:
      "Czy chcesz zakończyć pobieranie danych i przejść do innej strony",
    chooseMarketPlace: "Wybierz MarketPlace",
    returnToSearch: "Wróć do wyszukiwania",
    search: "Szukaj",
    docNumber: "Numer dokumentu",
    cardName: "Nazwa karty",
    noResultFound: "Nie znaleziono wyniku",
    pleaseTryAgainWithDifferentQuery: "Spróbuj ponownie z innym zapytaniem",
    errorWhenGetSearchData: "Błąd podczas pobierania danych wyszukiwania",
    searchResults: "Wyniki wyszukiwania",
    docDate: "Data dokumentu",
    clientNumber: "Numer klineta",
    clientName: "Nazwa klienta",
    allMarketPlace: "Wszytskie rynki",
    relatedDocuments: "Dokumenty powiązane",
    trackNumber: "Numer listu przewozowego",
    "Thulium status for user is successfully changed":
      "Thulium status dla użytkownika został pomyślnie zmieniony",
    "Failed when change Thulium status for user":
      "Niepowodzenie podczas zmiany statusu Thulium dla użytkownika",
    orderStatus: "Status zamówienia",
    "Click here": "Kliknij tutaj",
    docVatSum: "Dokumnet razem VAT",
    docTotal: "Dokument razem",
    "Document items before correction": "Pozycje dokumentu przed korektą",
    "Document items after correction": "Pozycje dokumentu po korekcie",
    invoices: "Faktury",
    "correction Invoices": "Faktury korygujące",
    "Correction invoices list": "Lista faktur korygujących",
    "Invoices list": "Lista faktur",
    "Correction document items": "Pozycje dokumentów korygujących",
    "Failed get invoices": "Nieudane pobieranie faktur",
    "Search invoice": "Wyszukaj fakturę",
    "Failed get correction invoices": "Nieudane pobranie faktur korygujących",
    "Search correction invoice": "Wyszukaj fakturę korygującą",
    Delivery: "Dostawa",
    Invoice: "Faktura",
    "Correction invoice": "Faktura korygująca",
    "Invalid date!": "Nieprawidłowa data!",
    "Deliveries list": "Lista dostaw",
    "Search delivery": "Wyszukaj dostawę",
    "Failed get deliveries": "Nieudane pobieranie dostaw",
    delivery: "dostawa",
    "Number at card": "Numer na karcie",
    "Card code": "Kod karty",
    deliveries: "Dostawy",
    "document details": "Szczegóły dokumentu",
    "Amount vat": "Kwota vat",
    "Unit price": "Cena jednostkowa",
    "Total gross": "Razem brutto",
    "Total vat": "Razem vat",
    Index: "Indeks",
    "Document items": "Pozycje dokumentu",
    "Online payment date": "Data płatności online",
    "Online payment Id": "Identyfikator płatności online",
    Courier: "Kurier",
    header: "Nagłowek",
    "Paid sum": "Zapłacona suma",
    "Shipping from": "Wysyłka z",
    "Delivery name": "Nazwa dostawycy",
    document: "Dokument",
    "Orders list": "Lista zamówień",
    "Search order": "Wyszukaj zamówienie",
    Download: "Pobierz",
    Yes: "Tak",
    No: "Nie",
    "Failed get orders": "Nieudane pobranie zamówień",
    Amount: "Kwota",
    "Released for shipping": "Zwolnione do wysyłki",
    "Start date": "Data rozpoczęcia",
    "End date": "Data zakończenia",
    "Select marketPlace": "Wybierz MarketPlace",
    Overview: "Podgląd",
    "Payment & Shipping": "Płatność i wysyłka",
    "Billing address": "Adres rozliczeniowy",
    "Shipping address": "Adres wysyłki",
    "Lines in document": "Linie w dokumencie",
    order: "zamówienie",
    Order: "Zamówienie",
    documentNumber: "Numer dokumentu",
    "Discount amount": "Kwota rabatu",
    "Created date": "Data utworzenia",
    "Created time": "Czas utworzenia",
    "Method payment": "Metoda płatności",
    "Payment Id": "Identyfikator płatności",
    "Delivery type": "Typ dostawy",
    "Released date for shipping": "Data zwolnienia do wysyłki",
    "Payment date": "Data płatności",
    "Item name": "Nazwa",
    Quantity: "Ilość",
    Price: "Cena",
    Total: "Razem",
    "Delivery target point": "Punkt docelowy dostawy",
    "Confirmed password": "Potwierdź hasło",
    "NIP/TAX": "NIP/TAX ID",
    "Reference number": "Numer referencyjny",
    "Customers list": "Lista klientów",
    "Failed get customer data": "Nie udało się pobrać danych klienta",
    "customers list": "Lista klientów",
    "customer details": "Szczegóły klienta",
    Details: "Szczegóły",
    Documents: "Dokumenty",
    "Failed get customers": "Nie udało się pobrać klientów",
    Code: "Kod",
    "Search customer": "Wyszukaj klienta",
    "No data found": "Nie znaleziono danych",
    Orders: "Zamówienia",
    orders: "Zamówienia",
    Invoices: "Faktury",
    Deliveries: "Dostawy",
    "Service orders": "Zlecenia serwisowe",
    "Correction invoices": "Faktury korygujące",
    serviceOrders: "Zlecenia serwisowe",
    correctionInvoices: "Faktury korygujące",
    Billing: "Rozliczenia",
    Shopping: "Dostawy",
    Addresses: "Adresy",
    "Company name": "Nazwa firmy",
    Country: "Kraj",
    "Post code": "Kod pocztowy",
    City: "Miasto",
    Street: "Ulica",
    "Street number": "Numer ulicy",
    Block: "Blok ",
    Building: "Budynek",
    "Contact persons": "Osoby kontaktowe",
    "No name": "Bez imienia",
    "First name": "Imie",
    Address: "Adres",
    "Download data": "Pobieranie danych",
    Search: "Szukaj",
    "Create date": "Data utworzenia",
    Number: "Numer",
    "Failed authorization": "Nieudana autoryzacja",
    "Ok, logout!": "Ok, wyloguj!",
    Active: "Aktywny",
    Users: "Użytkownicy",
    "Add group": "Dodaj grupe",
    Select: "Wybierz",
    Remove: "Usuń",
    Type: "Typ",
    "user details": "Szczegóły użytkownika",
    marketPlace: "MarketPlace",
    "Group list": "Lista grup",
    "Users list": "Lista użytkowników",
    "User status is successfully changed":
      "Status użytkownika został pomyślnie zmieniony",
    "Failed when change status user":
      "Niepowodzenie podczas zmiany statusu użytkownika",
    "User admin status is successfully changed":
      "Status administratora użytkownika został pomyślnie zmieniony",
    "Failed when change user admin status":
      "Błąd podczas zmiany statusu administratora użytkownika",
    "User moderator status is successfully changed":
      "Pomyślnie zmieniono status moderatora użytkownika",
    "Failed when change user moderator status":
      "Błąd podczas zmiany statusu moderatora użytkownika",
    "User group is successfully removed":
      "Grupa użytkowników została pomyślnie usunięta",
    Update: "Aktualizuj",
    "Edit details": "Edytuj szczegółów",
    "Full Name": "Pełna nazwa",
    "User data is successfully updated":
      "Dane użytkownika zostały pomyślnie zaktualizowane",
    "Failed when updated user":
      "Niepowodzenie podczas aktualizacji użytkownika",
    "Contact phone": "Telefon kontaktowy",
    "Failed change user password": "Nieudana zmiana hasła użytkownika",
    "User group is successfully changed":
      "Grupa użytkownika została pomyślnie usunięta",
    Group: "Grupa",
    "Failed when assign group to user":
      "Nie powiodło się przypisanie grupy do użytkownika",
    "Assign group to user is successfully":
      "Przypisanie grupy do użytkownika powiodło się",
    "Search user": "Wyszukaj użytkownika",
    Actions: "Akcje",
    Loading: "Ładowanie",
    View: "Podgląd",
    Info: "Informacje",
    Groups: "Grupy",
    "Failed get users": "Nie udało się pobrać użytkowników",
    Add: "Dodaj",
    "Please wait": "Proszę czekać",
    "Failed when added users": "Niepowodzenie podczas dodawania użytkowników",
    "Form has been successfully submitted":
      "Formularz został przesłany pomyślnie",
    validEmail: "Musi być prawidłowym adresem e-mail",
    validRequiredWithName: "{name} jest wymagane",
    validRequired: "To pole jest wymagane",
    validMatchWithName: "{name} muszą być takie same",
    validMinWithNumber: "Minimalna ilość znaków to {number}",
    validMaxNowDateWithName: "{name} nie może być późniejsza niż dzisiaj",
    validMinDateWithTwoName: "{name} musi być późniejsza niż {name2}",
    validOneOfElementsRequired: "Wymagany jest jeden z elementów",
    validOnlyNumber: "Muszą być tylko cyfry",
    validPhoneNumber: "Musi być poprawny numer telefonu",
    validRequiredWithNameWhenCheckedName:
      "{name} jest wymagane, gdy wybrano {name2}",
    "Add user": "Dodaj użytkownika",
    Name: "Imię",
    "Last name": "Nazwisko",
    Email: "Email",
    Password: "Hasło",
    Phone: "Telefon",
    Language: "Język",
    Poland: "Polska",
    England: "Anglia",
    Germany: "Niemcy",
    "Theme Mode": "Tryb motywu",
    Light: "Jasny",
    Dark: "Ciemny",
    "User permissions": "Uprawnienia użytkownika",
    Admin: "Administrator",
    Moderator: "Moderator",
    Cancel: "Anuluj",
    Submit: "Prześlij",
    "If not selected it will be normal user":
      "Jeżeli nie wybierane, ustawiony normalny użytkownik",
    "Ok, got it!": "OK, rozumiem!",
    "Sorry, looks like there are some errors detected, please try again":
      "Przepraszamy, wygląda na to, że wykryto błędy, spróbuj ponownie",
    "Password is successfully changed": "Hasło zostało pomyślnie zmienione",
    "After closing the window, the system will log you out":
      "Po zamknięciu okna system Cię wyloguje",
    "Password must be at least 8 character and contain symbols":
      "Hasło musi mieć co najmniej 8 znaków i zawierać symbole",
    "Confirm New Password": "Potwierdź nowe hasło",
    "New Password": "Nowe hasło",
    "New Passwords": "Nowe hasła",
    "Current Password": "Obecne hasło",
    "Update Password": "Zaktualizuj hasło",
    "users list": "Lista użytkowników",
    passwords: "Hasła",
    cancel: "Anuluj",
    moderator: "Moderator",
    changePassword: "Zmień hasło",
    phone: "Telefon",
    communication: "Komunikacja",
    signInMethod: "Metoda logowania",
    fullName: "Pełna nazwa",
    profileDetails: "Szczegoły profilu",
    homePage: "Strona głowna",
    admin: "Administrator",
    user: "Użytkownik",
    languages: "Języki",
    accountSettings: "Ustawienia konta",
    signOut: "Wyloguj",
    myProfile: "Mój profil",
    tryAgain: "Spróbuj ponownie",
    name: "Nazwa",
    email: "Email",
    pleaseWait: "Proszę czekać",
    login: "Zaloguj",
    forgetPassword: "Zapomniałeś hasła",
    password: "Hasło",
    dashboard: "Panel",
    pages: "Strony",
    profile: "Profil",
    profileOverview: "Podgląd",
    projects: "Projekty",
    campaigns: "Kampanie",
    documents: "Dokumnty",
    connections: "Połaczenia",
    wizards: "Wizards",
    horizontal: "Poziomy",
    vertical: "Pionowy",
    account: "Konto",
    accountOverview: "Podgląd",
    settings: "Ustawienia",
    authentication: "Uwierzytelnianie",
    basicFlow: "Podstawowy przepływ",
    signIn: "Zaloguj się",
    signUp: "Zarejestruj się",
    passwordReset: "Resetowanie hasła",
    multiStepSignUp: "Wieloetapowa rejestracja",
    error404: "Błąd 404",
    error500: "Błąd 500",
    apps: "Aplikacje",
    chat: "Czat",
    privateChat: "Czat prywatny",
    groupChat: "Czat grupowy",
    drawerChat: "Drawer Chat",
    widgets: "Widżety",
    widgetsLists: "Listy",
    widgetsStatistics: "Statystyki",
    widgetsCharts: "Wykresy",
    widgetsMixed: "Mixed",
    widgetsTables: "Tabele",
    widgetsFeeds: "Kanały",
    changelog: "Dziennik zmian",
    docsAndComponents: "Dokumenty i komponenty",
    megaMenu: "Mega Menu",
    exampleLink: "Przykładowy link",
    modals: "Modele",
    general: "Ogólne",
    inviteFriends: "Zaproś Przyjaciół",
    viewUsers: "Wyświetl użytkowników",
    upgradePlan: "Plan aktualizacji",
    shareAndEarn: "Udostępniaj i zarabiaj",
    forms: "Formularze",
    newTarget: "Nowy cel",
    newCard: "Nowa karta",
    newAddress: "Nowy adres",
    createAPIKey: "Utwórz klucz API",
    twoFactorAuth: "Uwierzytelnianie dwuetapowe",
    createApp: "Utwórz aplikację",
    createAccount: "Utwórz konto",
    documentation: "Dokumentacja",
    components: "Komponenty",
    resources: "Zasoby",
    activity: "Aktywność",
    customers: "Klienci",
    gettingStarted: "Pierwsze kroki",
    customersListing: "Lista klientów",
    customerDetails: "Szczegóły klienta",
    calendarApp: "Kalendarz",
    subscriptions: "Subskrypcje",
    getStarted: "Pierwsze kroki",
    subscriptionList: "Lista subskrypcji",
    addSubscription: "Dodaj subskrypcję",
    viewSubscription: "Wyświetl subskrypcję",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "pl",
  globalInjection: true,
  messages,
});

export default i18n;
